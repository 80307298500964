
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91accountId_937vBEP95L6NMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/accounts/[id]/accounts/[accountId].vue?macro=true";
import { default as indexxZhPC8ajd2Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/accounts/[id]/index.vue?macro=true";
import { default as _91id_93ve4WSGJJkWMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/accounts/[id].vue?macro=true";
import { default as indexzstjwHus5YMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/accounts/index.vue?macro=true";
import { default as _91id_93H5tXxCqsr6Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/bundles/bundle-choices/[id].vue?macro=true";
import { default as indexffTkvgDiFjMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/bundles/bundle-choices/index.vue?macro=true";
import { default as _91id_93xXcVpDxrtDMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/bundles/bundles/[id].vue?macro=true";
import { default as indexzkA0om1wujMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/bundles/bundles/index.vue?macro=true";
import { default as indexRCNDU9NkPBMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/bundles/index.vue?macro=true";
import { default as indexWUByAz5ZidMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/channel-settlements/[id]/index.vue?macro=true";
import { default as logOQFoVFRXUFMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/channel-settlements/[id]/log.vue?macro=true";
import { default as ordersGNU9kwkOb7Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/channel-settlements/[id]/orders.vue?macro=true";
import { default as _91channelSettlementSnapshotId_93YddsEazNv1Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/channel-settlements/[id]/snapshots/[channelSettlementSnapshotId].vue?macro=true";
import { default as index7HxgZbq03WMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/channel-settlements/[id]/snapshots/index.vue?macro=true";
import { default as _91id_93s1eiTS3u4tMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/channel-settlements/[id].vue?macro=true";
import { default as card_45readersXcpe4CJeQBMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/connections/[id]/card-readers.vue?macro=true";
import { default as connected_45storesbe5hMPoeDyMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/connections/[id]/connected-stores.vue?macro=true";
import { default as deliveriesRjP3Q7noDbMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/connections/[id]/deliveries.vue?macro=true";
import { default as webhook0KlRrhAWg7Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/connections/[id]/webhook.vue?macro=true";
import { default as _91id_931VFXabHaKDMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/connections/[id].vue?macro=true";
import { default as _91driver_93nx1NicF6rbMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/connections/create/[driver].vue?macro=true";
import { default as indexNLs7jw9AAtMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/connections/create/index.vue?macro=true";
import { default as indexbSaXWRAranMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/connections/index.vue?macro=true";
import { default as index0AjvW5aXRcMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/customer-sites/[id]/index.vue?macro=true";
import { default as payment_45methodsprjj6KtD2AMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/customer-sites/[id]/payment-methods.vue?macro=true";
import { default as _91id_93AXBHGnxD4oMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/customer-sites/[id].vue?macro=true";
import { default as index7nMsYxelTmMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/customer-sites/index.vue?macro=true";
import { default as deliveriesc7Y9d0IerbMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/deliveries.vue?macro=true";
import { default as _91id_93jDsyCVPv1HMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/discounts/discounts/[id].vue?macro=true";
import { default as indexqlhdALxYTDMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/discounts/discounts/index.vue?macro=true";
import { default as index5pXMb2EltmMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/discounts/index.vue?macro=true";
import { default as _91id_93cdoKRSiRiZMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/employees/[id].vue?macro=true";
import { default as indexGS2hjJrYDaMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/employees/index.vue?macro=true";
import { default as _91id_93R9BQ8cjHAPMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/gallery/[id].vue?macro=true";
import { default as index4EhsVbgp6gMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/gallery/index.vue?macro=true";
import { default as indexEyRsE55ZB5Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/index.vue?macro=true";
import { default as indexqOcj1Ct1FdMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/kitchens/index.vue?macro=true";
import { default as modifier_45groups45g4DJ0d0VMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/kitchens/modifier-groups.vue?macro=true";
import { default as modifierswWxLI5JA8sMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/kitchens/modifiers.vue?macro=true";
import { default as kitchensdCfCG8IVijMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/kitchens.vue?macro=true";
import { default as _91menuCategoryId_93BTVaLaCRgdMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/menus/[id]/categories/[menuCategoryId].vue?macro=true";
import { default as index9pGRcwGNmbMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/menus/[id]/index.vue?macro=true";
import { default as indexu6zF4a5urDMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/menus/index.vue?macro=true";
import { default as indexudDdHDRuzkMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/modifiers/index.vue?macro=true";
import { default as indexSKmA9gYpUCMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/modifiers/modifier-groups/[id]/index.vue?macro=true";
import { default as _91modifierId_937D02TeiWBrMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/modifiers/modifier-groups/[id]/modifiers/[modifierId].vue?macro=true";
import { default as _91id_93q8FGcgfeYHMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/modifiers/modifier-groups/[id].vue?macro=true";
import { default as indexxDLWHek98PMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/modifiers/modifier-groups/index.vue?macro=true";
import { default as _91driver_93IThOpSphStMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/oauth/[driver].vue?macro=true";
import { default as _91id_93fopUhV0OpxMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/orders/[id].vue?macro=true";
import { default as indexnJQPJTp6VFMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/orders/index.vue?macro=true";
import { default as indexaDv7WbWv5xMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/products/index.vue?macro=true";
import { default as _91id_93dMB5UPJVhOMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/products/product-categories/[id].vue?macro=true";
import { default as indexCD0SKscde6Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/products/product-categories/index.vue?macro=true";
import { default as _91id_93hw2o4UAbNdMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/products/products/[id].vue?macro=true";
import { default as indexajY1W0y9yiMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/products/products/index.vue?macro=true";
import { default as profile0afj1Ij3ySMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/profile.vue?macro=true";
import { default as cancellationsYYmKX8IK3BMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/reports/cancellations.vue?macro=true";
import { default as cash_45transferszeG0OibiWiMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/reports/cash-transfers.vue?macro=true";
import { default as closing_45errorsHCeoinbOrbMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/reports/closing-errors.vue?macro=true";
import { default as deliveryHIypwendwSMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/reports/delivery.vue?macro=true";
import { default as downloadAv496xsO18Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/reports/download.vue?macro=true";
import { default as efficiency63IROphXOtMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/reports/efficiency.vue?macro=true";
import { default as guestsAxa8CpK1PqMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/reports/guests.vue?macro=true";
import { default as index0RlDCmRoTDMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/reports/index.vue?macro=true";
import { default as paymentsIPwsNOAjJYMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/reports/payments.vue?macro=true";
import { default as productionwJmJEGsfwQMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/reports/production.vue?macro=true";
import { default as reservationsSh2kh7JGyWMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/reports/reservations.vue?macro=true";
import { default as reviews0N90YGXTnGMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/reports/reviews.vue?macro=true";
import { default as tipsChdo1uHLccMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/reports/tips.vue?macro=true";
import { default as accounts2P12pCRMONMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/settings/accounts.vue?macro=true";
import { default as financesHH0j5CUR1NMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/settings/finances.vue?macro=true";
import { default as indextIal4xutMmMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/settings/index.vue?macro=true";
import { default as integrationsgO7hZ1wxmOMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/settings/integrations.vue?macro=true";
import { default as operationskZDeR7dOfvMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/settings/operations.vue?macro=true";
import { default as users39giRVDcdcMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/settings/users.vue?macro=true";
import { default as settings1ZP9t2dmRaMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/settings.vue?macro=true";
import { default as indexrjyemMQYYGMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/stock/index.vue?macro=true";
import { default as modifier_45recipeszdzAlIHPK4Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/stock/modifier-recipes.vue?macro=true";
import { default as variant_45recipesaLjigYx1L3Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/stock/variant-recipes.vue?macro=true";
import { default as stockJqgbX12GU4Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/stock.vue?macro=true";
import { default as bookkeepingctgMO1721ZMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/store-settlements/[id]/bookkeeping.vue?macro=true";
import { default as channel_45settlementsJSkQDrgXAkMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/store-settlements/[id]/channel-settlements.vue?macro=true";
import { default as indexsA12xjD0bQMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/store-settlements/[id]/index.vue?macro=true";
import { default as logup8XB8gmTYMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/store-settlements/[id]/log.vue?macro=true";
import { default as ordersPwxTEWeEAbMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/store-settlements/[id]/orders.vue?macro=true";
import { default as _91reconciliationId_93Grl0gRrefmMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/store-settlements/[id]/reconciliations/[reconciliationId].vue?macro=true";
import { default as indexzFSU0Ta10HMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/store-settlements/[id]/reconciliations/index.vue?macro=true";
import { default as _91id_931zgfJ5hHkpMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/store-settlements/[id].vue?macro=true";
import { default as indexaT2m0DqE9QMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/store-settlements/index.vue?macro=true";
import { default as bookkeepingxNqsKGpoj7Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/stores/[id]/bookkeeping.vue?macro=true";
import { default as indexj4NrUW3ATIMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/stores/[id]/index.vue?macro=true";
import { default as kitchenwbt6YdoqckMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/stores/[id]/kitchen.vue?macro=true";
import { default as logA2N9mgLHV2Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/stores/[id]/log.vue?macro=true";
import { default as stockleh1cTPTe1Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/stores/[id]/stock.vue?macro=true";
import { default as _91id_931g77QtMBuNMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/stores/[id].vue?macro=true";
import { default as indexbujrOcENzvMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/stores/index.vue?macro=true";
import { default as indexeAjpIXl2qqMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/terminals/colors/index.vue?macro=true";
import { default as _91id_93FQAu3Mp2JzMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/terminals/folders/[id].vue?macro=true";
import { default as index0XiiK5jaGfMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/terminals/folders/index.vue?macro=true";
import { default as channel_45settlements84E70fIkCyMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/terminals/terminals/[id]/channel-settlements.vue?macro=true";
import { default as indexhNuCbM1lXyMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/terminals/terminals/[id]/index.vue?macro=true";
import { default as logjmMDS641kIMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/terminals/terminals/[id]/log.vue?macro=true";
import { default as navigationrpsio0JQOrMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/terminals/terminals/[id]/navigation.vue?macro=true";
import { default as _91id_93wt4xLrgpSLMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/terminals/terminals/[id].vue?macro=true";
import { default as indexMwsJI9DLN8Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/terminals/terminals/index.vue?macro=true";
export default [
  {
    name: _91id_93ve4WSGJJkWMeta?.name,
    path: "/accounts/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/accounts/[id].vue"),
    children: [
  {
    name: "accounts-id-accounts-accountId",
    path: "accounts/:accountId()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/accounts/[id]/accounts/[accountId].vue")
  },
  {
    name: "accounts-id",
    path: "",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/accounts/[id]/index.vue")
  }
]
  },
  {
    name: "accounts",
    path: "/accounts",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/accounts/index.vue")
  },
  {
    name: "bundles-bundle-choices-id",
    path: "/bundles/bundle-choices/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/bundles/bundle-choices/[id].vue")
  },
  {
    name: "bundles-bundle-choices",
    path: "/bundles/bundle-choices",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/bundles/bundle-choices/index.vue")
  },
  {
    name: "bundles-bundles-id",
    path: "/bundles/bundles/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/bundles/bundles/[id].vue")
  },
  {
    name: "bundles-bundles",
    path: "/bundles/bundles",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/bundles/bundles/index.vue")
  },
  {
    name: "bundles",
    path: "/bundles",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/bundles/index.vue")
  },
  {
    name: _91id_93s1eiTS3u4tMeta?.name,
    path: "/channel-settlements/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/channel-settlements/[id].vue"),
    children: [
  {
    name: "channel-settlements-id",
    path: "",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/channel-settlements/[id]/index.vue")
  },
  {
    name: "channel-settlements-id-log",
    path: "log",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/channel-settlements/[id]/log.vue")
  },
  {
    name: "channel-settlements-id-orders",
    path: "orders",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/channel-settlements/[id]/orders.vue")
  },
  {
    name: "channel-settlements-id-snapshots-channelSettlementSnapshotId",
    path: "snapshots/:channelSettlementSnapshotId()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/channel-settlements/[id]/snapshots/[channelSettlementSnapshotId].vue")
  },
  {
    name: "channel-settlements-id-snapshots",
    path: "snapshots",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/channel-settlements/[id]/snapshots/index.vue")
  }
]
  },
  {
    name: "connections-id",
    path: "/connections/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/connections/[id].vue"),
    children: [
  {
    name: "connections-id-card-readers",
    path: "card-readers",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/connections/[id]/card-readers.vue")
  },
  {
    name: "connections-id-connected-stores",
    path: "connected-stores",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/connections/[id]/connected-stores.vue")
  },
  {
    name: "connections-id-deliveries",
    path: "deliveries",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/connections/[id]/deliveries.vue")
  },
  {
    name: "connections-id-webhook",
    path: "webhook",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/connections/[id]/webhook.vue")
  }
]
  },
  {
    name: "connections-create-driver",
    path: "/connections/create/:driver()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/connections/create/[driver].vue")
  },
  {
    name: "connections-create",
    path: "/connections/create",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/connections/create/index.vue")
  },
  {
    name: "connections",
    path: "/connections",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/connections/index.vue")
  },
  {
    name: _91id_93AXBHGnxD4oMeta?.name,
    path: "/customer-sites/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/customer-sites/[id].vue"),
    children: [
  {
    name: "customer-sites-id",
    path: "",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/customer-sites/[id]/index.vue")
  },
  {
    name: "customer-sites-id-payment-methods",
    path: "payment-methods",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/customer-sites/[id]/payment-methods.vue")
  }
]
  },
  {
    name: "customer-sites",
    path: "/customer-sites",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/customer-sites/index.vue")
  },
  {
    name: "deliveries",
    path: "/deliveries",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/deliveries.vue")
  },
  {
    name: "discounts-discounts-id",
    path: "/discounts/discounts/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/discounts/discounts/[id].vue")
  },
  {
    name: "discounts-discounts",
    path: "/discounts/discounts",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/discounts/discounts/index.vue")
  },
  {
    name: "discounts",
    path: "/discounts",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/discounts/index.vue")
  },
  {
    name: "employees-id",
    path: "/employees/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/employees/[id].vue")
  },
  {
    name: "employees",
    path: "/employees",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/employees/index.vue")
  },
  {
    name: "gallery-id",
    path: "/gallery/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/gallery/[id].vue")
  },
  {
    name: "gallery",
    path: "/gallery",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/gallery/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/index.vue")
  },
  {
    name: kitchensdCfCG8IVijMeta?.name,
    path: "/kitchens",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/kitchens.vue"),
    children: [
  {
    name: "kitchens",
    path: "",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/kitchens/index.vue")
  },
  {
    name: "kitchens-modifier-groups",
    path: "modifier-groups",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/kitchens/modifier-groups.vue")
  },
  {
    name: "kitchens-modifiers",
    path: "modifiers",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/kitchens/modifiers.vue")
  }
]
  },
  {
    name: "menus-id-categories-menuCategoryId",
    path: "/menus/:id()/categories/:menuCategoryId()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/menus/[id]/categories/[menuCategoryId].vue")
  },
  {
    name: "menus-id",
    path: "/menus/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/menus/[id]/index.vue")
  },
  {
    name: "menus",
    path: "/menus",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/menus/index.vue")
  },
  {
    name: "modifiers",
    path: "/modifiers",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/modifiers/index.vue")
  },
  {
    name: _91id_93q8FGcgfeYHMeta?.name,
    path: "/modifiers/modifier-groups/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/modifiers/modifier-groups/[id].vue"),
    children: [
  {
    name: "modifiers-modifier-groups-id",
    path: "",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/modifiers/modifier-groups/[id]/index.vue")
  },
  {
    name: "modifiers-modifier-groups-id-modifiers-modifierId",
    path: "modifiers/:modifierId()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/modifiers/modifier-groups/[id]/modifiers/[modifierId].vue")
  }
]
  },
  {
    name: "modifiers-modifier-groups",
    path: "/modifiers/modifier-groups",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/modifiers/modifier-groups/index.vue")
  },
  {
    name: "oauth-driver",
    path: "/oauth/:driver()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/oauth/[driver].vue")
  },
  {
    name: "orders-id",
    path: "/orders/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/orders/[id].vue")
  },
  {
    name: "orders",
    path: "/orders",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/orders/index.vue")
  },
  {
    name: "products",
    path: "/products",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/products/index.vue")
  },
  {
    name: "products-product-categories-id",
    path: "/products/product-categories/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/products/product-categories/[id].vue")
  },
  {
    name: "products-product-categories",
    path: "/products/product-categories",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/products/product-categories/index.vue")
  },
  {
    name: "products-products-id",
    path: "/products/products/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/products/products/[id].vue")
  },
  {
    name: "products-products",
    path: "/products/products",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/products/products/index.vue")
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/profile.vue")
  },
  {
    name: "reports-cancellations",
    path: "/reports/cancellations",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/reports/cancellations.vue")
  },
  {
    name: "reports-cash-transfers",
    path: "/reports/cash-transfers",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/reports/cash-transfers.vue")
  },
  {
    name: "reports-closing-errors",
    path: "/reports/closing-errors",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/reports/closing-errors.vue")
  },
  {
    name: "reports-delivery",
    path: "/reports/delivery",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/reports/delivery.vue")
  },
  {
    name: "reports-download",
    path: "/reports/download",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/reports/download.vue")
  },
  {
    name: "reports-efficiency",
    path: "/reports/efficiency",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/reports/efficiency.vue")
  },
  {
    name: "reports-guests",
    path: "/reports/guests",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/reports/guests.vue")
  },
  {
    name: "reports",
    path: "/reports",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/reports/index.vue")
  },
  {
    name: "reports-payments",
    path: "/reports/payments",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/reports/payments.vue")
  },
  {
    name: "reports-production",
    path: "/reports/production",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/reports/production.vue")
  },
  {
    name: "reports-reservations",
    path: "/reports/reservations",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/reports/reservations.vue")
  },
  {
    name: "reports-reviews",
    path: "/reports/reviews",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/reports/reviews.vue")
  },
  {
    name: "reports-tips",
    path: "/reports/tips",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/reports/tips.vue")
  },
  {
    name: settings1ZP9t2dmRaMeta?.name,
    path: "/settings",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/settings.vue"),
    children: [
  {
    name: "settings-accounts",
    path: "accounts",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/settings/accounts.vue")
  },
  {
    name: "settings-finances",
    path: "finances",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/settings/finances.vue")
  },
  {
    name: "settings",
    path: "",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/settings/index.vue")
  },
  {
    name: "settings-integrations",
    path: "integrations",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/settings/integrations.vue")
  },
  {
    name: "settings-operations",
    path: "operations",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/settings/operations.vue")
  },
  {
    name: "settings-users",
    path: "users",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/settings/users.vue")
  }
]
  },
  {
    name: stockJqgbX12GU4Meta?.name,
    path: "/stock",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/stock.vue"),
    children: [
  {
    name: "stock",
    path: "",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/stock/index.vue")
  },
  {
    name: "stock-modifier-recipes",
    path: "modifier-recipes",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/stock/modifier-recipes.vue")
  },
  {
    name: "stock-variant-recipes",
    path: "variant-recipes",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/stock/variant-recipes.vue")
  }
]
  },
  {
    name: _91id_931zgfJ5hHkpMeta?.name,
    path: "/store-settlements/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/store-settlements/[id].vue"),
    children: [
  {
    name: "store-settlements-id-bookkeeping",
    path: "bookkeeping",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/store-settlements/[id]/bookkeeping.vue")
  },
  {
    name: "store-settlements-id-channel-settlements",
    path: "channel-settlements",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/store-settlements/[id]/channel-settlements.vue")
  },
  {
    name: "store-settlements-id",
    path: "",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/store-settlements/[id]/index.vue")
  },
  {
    name: "store-settlements-id-log",
    path: "log",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/store-settlements/[id]/log.vue")
  },
  {
    name: "store-settlements-id-orders",
    path: "orders",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/store-settlements/[id]/orders.vue")
  },
  {
    name: "store-settlements-id-reconciliations-reconciliationId",
    path: "reconciliations/:reconciliationId()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/store-settlements/[id]/reconciliations/[reconciliationId].vue")
  },
  {
    name: "store-settlements-id-reconciliations",
    path: "reconciliations",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/store-settlements/[id]/reconciliations/index.vue")
  }
]
  },
  {
    name: "store-settlements",
    path: "/store-settlements",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/store-settlements/index.vue")
  },
  {
    name: _91id_931g77QtMBuNMeta?.name,
    path: "/stores/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/stores/[id].vue"),
    children: [
  {
    name: "stores-id-bookkeeping",
    path: "bookkeeping",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/stores/[id]/bookkeeping.vue")
  },
  {
    name: "stores-id",
    path: "",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/stores/[id]/index.vue")
  },
  {
    name: "stores-id-kitchen",
    path: "kitchen",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/stores/[id]/kitchen.vue")
  },
  {
    name: "stores-id-log",
    path: "log",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/stores/[id]/log.vue")
  },
  {
    name: "stores-id-stock",
    path: "stock",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/stores/[id]/stock.vue")
  }
]
  },
  {
    name: "stores",
    path: "/stores",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/stores/index.vue")
  },
  {
    name: "terminals-colors",
    path: "/terminals/colors",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/terminals/colors/index.vue")
  },
  {
    name: "terminals-folders-id",
    path: "/terminals/folders/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/terminals/folders/[id].vue")
  },
  {
    name: "terminals-folders",
    path: "/terminals/folders",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/terminals/folders/index.vue")
  },
  {
    name: _91id_93wt4xLrgpSLMeta?.name,
    path: "/terminals/terminals/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/terminals/terminals/[id].vue"),
    children: [
  {
    name: "terminals-terminals-id-channel-settlements",
    path: "channel-settlements",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/terminals/terminals/[id]/channel-settlements.vue")
  },
  {
    name: "terminals-terminals-id",
    path: "",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/terminals/terminals/[id]/index.vue")
  },
  {
    name: "terminals-terminals-id-log",
    path: "log",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/terminals/terminals/[id]/log.vue")
  },
  {
    name: "terminals-terminals-id-navigation",
    path: "navigation",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/terminals/terminals/[id]/navigation.vue")
  }
]
  },
  {
    name: "terminals-terminals",
    path: "/terminals/terminals",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250402074654/pages/terminals/terminals/index.vue")
  }
]